.body-area {
  min-height: 400px;
  border-radius: 3px;
}

body {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.top-bar-branded {
  border-top: 5px solid $genghis-orange;
}


.panel {
  $panel-gray: #f5f5f5;
  $panel-borders: #ddd;
  border: 1px solid $panel-borders;
  border-radius: 3px;
  margin-bottom: 10px;


  .panel-body {
    min-height: 100px;
  }
  .panel-header {
    font-size: 1.1rem;
    color: #333;
    background: $panel-gray;
  }
  .panel-footer {
    border-top: 1px solid $panel-borders;
  }
  .panel-header, .panel-body, .panel-footer {
    padding: 4px 8px;
  }
}

.bottom-hr {
  border-top: 1px solid #cacaca;
}

[v-cloak] {
  display: none;
}

.el-dialog {
  width: 90% !important;
}

.fullscreen .el-dialog {
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
}

.fullscreen el-dialog__footer{
  background: white;
}

@screen md{
  .el-dialog {
    width: 50% !important;
  }
  .large-dialog .el-dialog{
    width: 80% !important;
  }
  .fullscreen .el-dialog {
    width: 100% !important;
    height: 100vh !important;
    margin-top: 0 !important;
  }
}

.fullscreen-iframe {
  min-height: 90vh !important;
}

input[type=file] {
  padding: 5px;
}

input, select {
  padding-left: 10px;
}

.success.button {
  color: white;
}

.success.button:hover{
  color: #cacaca;
}

h1{
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 3rem
}
h2{
  font-size: 2rem;
  font-weight: 500;
  line-height: 3rem
}
h3{
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 3rem
}
h4{
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem
}
h5{
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 2rem
}
h6 {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.7rem
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  width: 100%;
}
.inline-code{
  display: inline;
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  font-size: 0.7rem;
}

.explanation {
  display: inline;
  font-size: 0.8rem;
  color: #444;
  font-stretch: condensed;
  font-weight: 500;
  line-height: 1rem;
}

.border-top{
  border-top: 1px solid !important;
}
.border-bottom {
  border-bottom: 1px solid !important;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.button-group {
  border-radius: 3px;
}

.button-group .button {
  border-radius: 0;
}

.button-group .button:not(:last-child) ,
.button-group :not(:last-child) .button {
  margin-right: 1px;
}

.button-group > :first-child,
.button-group :first-child .button {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.button-group > :last-child,
.button-group :last-child .button {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.button-group.no-gaps > :not(:last-child),
.button-group.no-gaps :not(:last-child) .button {
  margin-right: 0;
}

