body, body p{
  @apply text-gray-900;
}
html {
  scroll-behavior: smooth;
}
.content {
  @apply bg-gray-200 rounded-sm p-2 text-gray-900 mb-4 mr-4 border-solid border border-gray-300;
  min-height: 4rem;
  box-shadow: 0px 0px 5px 0px #e2e8f0;
  box-sizing: border-box;
}

.div-shadow {
    @apply border border-solid border-gray-300;
    box-shadow: 0px 0px 5px 0px #e2e8f0;
    box-sizing: border-box;
}

.form.wide {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  input, select, textarea {
    padding-left: 10px;
    padding-right: 25px;
  }
  textarea {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.form.wide .holder {
  @apply w-full;
  display: inline-block;
  margin-bottom: 20px;
  align-items: flex-start;
  align-self: flex-start;
}

.form.wide .holder .el-input {
  width: 100% !important;
}

.form.wide .holder .el-date-editor input{
  padding-left: 30px;
  padding-right: 30px;
}

.form.wide .holder input,
.form.wide .holder textarea,
.form.wide .holder select,
.form.wide .holder .el-select input,
.form.wide .holder .el-input input{
  background-color: #fefefe;
  border-radius: 3px;
  border: 1px solid #cacaca;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  color: #0a0a0a;
  width: 100%;
  margin-bottom: 0;
  font-size: 1em;
}

.form.wide .holder input[type=checkbox]{
  box-shadow: none;
  border: none;
  width: 15px;
}

.form.wide .form-error.is-visible {
    margin-top: 5px;
}

form label span.asterisk{
  color: #c20000;
}

form .help-text {
  margin-top: 5px;
  font-size: 0.7rem;
  color: $genghis-orange;
}

@screen md{
  .form.wide.cols4 .holder {
    @apply px-4;
    width: 24%;
  }
  .form.wide.cols3 .holder {
    @apply w-1/4 px-4;
    width: 33%;
  }

  .form.wide.cols2 .holder {
    @apply w-1/3 px-4;
    width: 49%;
  }

  .form.wide.cols1 .holder {
    @apply w-full px-4;
    width: 98%;
  }

  .form.wide .holder {
    input[type=checkbox] + label {
      margin-top: 35px
    }
  }
}

.page-content {
  @apply w-full font-normal;
  margin-bottom: 20px;
}
.page-content label{
  @apply text-gray-600 font-normal;
}

@screen md{
  .page-content{
    @apply w-1/4;
  }
}


